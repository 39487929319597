.home-container {
	width: 100%;
	display: flex;
	overflow: auto;
	min-height: 100vh;
	padding: 50px 0;
	align-items: center;
	flex-direction: column;
	background-size: cover;
	justify-content: center;
	background-image: url("/playground_assets/blizzard_bg-1500w.webp");
}

.image-bobby-kotick {
	width: var(--dl-size-size-xxlarge);
	box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
	object-fit: cover;
	aspect-ratio: 2/3;
	margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.image-blizzard-logo {
	width: var(--dl-size-size-large);
	filter: grayscale(100);
	object-fit: cover;
	margin-bottom: var(--dl-space-space-oneandhalfunits);
}

h1, h2, h3, p {
	text-align: center;
	font-family: Barlow;
}

h1 {
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 2px;
}

h2 {
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 3px;
}

h3 {
	font-size: 22px;
	font-weight: 600;
	letter-spacing: 3px;
}

p {
	font-size: 18px;
}

.button-incense {
	box-sizing: border-box;
	width: auto;
	margin: 1em .25em;
	padding: .5em 1em;
	border: 2px solid rgba(255, 255, 255, 0.15);
	border-radius: 100px;
	color: #FFF;
	text-shadow: 0 4px 8px rgb(0 0 0 / 20%);
	white-space: normal;
	cursor: pointer;
}

.button-incense[type="primary"] {
	background-color: rgb(51, 177, 255);
	background-image: linear-gradient(180deg, #1888EF 0%, #009FE9 100%);
}

.button-incense[type="primary"]:hover {
	background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

.button-incense[type="secondary"] {
	background-color: rgba(93, 113, 152, 0.4);
}
.button-incense[type="secondary"]:hover {
	background-color: rgba(93, 113, 152, 0.6);
}

.button-incense[disabled] {
	border-style: dashed;
	background-color: rgba(93, 113, 152, 0.4) !important;
	background-image: none;
	color: #8697aa;
	filter: grayscale(.5);
}
.button-incense[disabled]:hover {
	background-image: none;
}


.aplayer {
	border: 2px solid rgba(255, 255, 255, 0.15);
	border-radius: 8px;
	background: #3d5470;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time,
.aplayer .aplayer-info .aplayer-music .aplayer-author {
	color: #8697aa;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-loaded {
	background: #8697aa;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon path {
	fill: #8697aa;
}
.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon:hover path {
	fill: #b6cde7;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar {
	background: rgba(220, 234, 250, 0.75);
}
